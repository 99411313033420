import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import theme from "./../../theme";

const useStyles = makeStyles((theme) => ({
  "@keyframes bgAnim1": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
  backgroundAnim: {
    background:
      "linear-gradient(90deg, rgba(196,196,196,1) 0%, rgba(229,229,229,1) 100%)",
    backgroundSize: "400% 400%",
    animation: `$bgAnim1 1.5s ease infinite`,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  text: {
    height: "10px",
  },
  infoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: 200,
    width: 240,
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));


function InfoMessage() {
  const classes = useStyles(theme);

  return (
    <Box className={classes.infoBox}>
      <Box
        className={`${classes.text} ${classes.backgroundAnim}`}
        width={250}
        height={180}
        flex={3}
      />
      <Box
        mt={1}
        className={`${classes.backgroundAnim}`}
        width={250}
        height={30}
        flex={1}
      ></Box>
    </Box>
  );
}

function LoadingCard() {
  const classes = useStyles(theme);

  return (
    <Box className={classes.container}>
      <InfoMessage />
    </Box>
  );
}

export default LoadingCard;
