/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Image from "material-ui-image";
import theme from "../../theme";
import Link from "../link";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "230px",
    height: "230px",
    width: "260px",
    border: theme.borders[5],
    cursor: "pointer",
    transition: "all 0.3s",
  },
  cardDesc: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  titleText: {
    fontSize: "13px",
    fontWeight: 700,
    display: "inline-block",
    maxWidth: 230,
    textOverflow: "ellipsis",
    maxHeight: 40,
    overflow: "hidden",
  },
  text: {
    fontSize: 12,
  },
  noLinkStyle: {
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const AssetCardComponent = ({ id, name, image, to }) => {
  const classes = useStyles(theme);

  return (
    <Link className={classes.noLinkStyle} to={to} key={id}>
      <Box className={classes.card}>
        <Image
          src={image}
          style={{
            height: "100%",
            padding: "0",
            width: "100%",
            filter: "brightness(70%)",
            flex: 1,
          }}
          imageStyle={{
            objectFit: "cover",
            height: "100%",
            width: "100%",
          }}
        />

        <Box height="60px" className={classes.cardDesc} textAlign="left">
          <Box display="flex" flexDirection="row">
            <Box color="text.primary">
              <Typography variant="body1" className={classes.titleText}>
                {name}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Link>
  );
};

export default AssetCardComponent;
