/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import AssetsListComponent from "./assets-list";

const RegionAssetsComponent = ({ region, assets, loadingRegion, loadingAssets }) => {
  const [height, setHeight] = useState(0);

  const resizeBox = () => {
    const box = document.getElementById("assets-list");
    if (box) {
      setHeight(window.innerHeight - (window.screen.width < 600 ? 148 : 84));
      if (box.scrollTop === 0 || box.scrollTop - box.scrollHeight < 50) {
        box.scrollTop = box.scrollHeight;
      }
    }
  };

  useEffect(() => {
    resizeBox();

    window.addEventListener("resize", resizeBox);
    return () => {
      window.removeEventListener("resize", resizeBox);
    };
  }, []);

  useEffect(() => {
    resizeBox();
  }, [region]);

  return (
    <Box id="assets-list" height={height}>
      <Grid container direction="row">
        <Grid item xs={12} md={12} lg={12}>
          <Box position="relative">
            <AssetsListComponent
              region={region}
              assets={assets}
              loadingRegion={loadingRegion}
              loadingAssets={loadingAssets}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegionAssetsComponent;
