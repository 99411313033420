import React, { useEffect } from "react";
import { useParams } from "@reach/router";
import { useDispatch, useSelector } from "react-redux";
import { getAssetsThunk } from "../../redux/actions/misc-actions";
import { getRegionThunk } from "../../redux/actions/region-actions";
import RegionAssetsComponent from "../../components/region-assets";

const RegionAssetsContainer = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const assets = useSelector((state) => state.misc.assets || []);
  const region = useSelector((state) => state.region.region);
  const loadingRegion = useSelector((state) => state.region.status);
  const loadingAssets = useSelector((state) => state.misc.assetsStatus);

  useEffect(() => {
    if (params && params.regionId) {
      dispatch(getRegionThunk(params.regionId));
      dispatch(
        getAssetsThunk({
          numberPerPage: 200,
          regions: params.regionId,
        }),
      );
    }
  }, []);

  return (
    <RegionAssetsComponent
      region={region}
      assets={assets}
      loadingRegion={loadingRegion}
      loadingAssets={loadingAssets}
    />
  );
};

export default RegionAssetsContainer;
